<template>
  <courseware-form
    ref="formRef"
    :is-version-detail="pageState.action === 'version-detail'"
    :is-related="true"
    :title="pageState.title"
    v-model:current="pageState.current"
    :readOnly="pageState.readOnly"
    @onSave="handleSave"
  />
  <lesson-plan-editor
    :readOnly="pageState.readOnly"
    @onSave="handleSaveLessonPlan"
    :textContent="pageState.current.plan_content"
    ref="sendText"
    @onChange="handleChange"
  />
</template>

<script setup >
import { useRoute } from 'vue-router'
import { reactive, onBeforeMount, computed, getCurrentInstance, ref } from 'vue'
import CoursewareForm from '../components/CoursewareForm.vue'
import LessonPlanEditor from '@/components/lesson-plan-editor/LessonPlanEditor.vue'
import {
  createCourseware,
  getCoursewareInfo,
  getCoursewareVersionInfo,
  editCourseware,
  setCoursewareTags
} from '@/services/courseware'
import { getTeachPlanTemplateInfo } from '@/services/teach-plan-template'
import { message } from 'ant-design-vue'

// const pageTypeMap = ['edit', 'detail', 'version-detail']

const route = useRoute()

const currentInstance = getCurrentInstance()

console.log('edit', route.params.action, route.params.id) // 0 为创建

const pageState = reactive({
  loading: false,
  title: '新建教案',
  action: route.params.action,
  readOnly: route.params.action !== 'edit', // 是为预览， 否为编辑
  id: parseInt(route.params.id),
  templateId: parseInt(route.params.templateId),
  current: {}
})

// 初始化
if (pageState.action === 'edit') {
  if (pageState.id) {
    pageState.title = '编辑教案'
  } else {
    pageState.title = '新建教案'
    pageState.current.url = ''
  }
} else {
  pageState.title = '查看教案'
}

const queryParams = computed(() => {
  const params = {
    author_type: 'mohuan',
    plan_content: pageState.current.plan_content,
    relate_courseware_ids: pageState.current.relate_courseware_ids,
    name: pageState.current.name,
    type: pageState.current.type,
    size: pageState.current.size,
    url: pageState.current.url,
    md5: pageState.current.md5,
    share_mode: pageState.current.share_mode
  }
  if (!pageState.current.book_section_id || pageState.current.book_section_id.length === 0) {
    params.book_section_id = 0
    params.book_id = 0
  } else if (pageState.current.book_section_id.length === 1) {
    params.book_id = pageState.current.book_section_id[0]
    params.book_section_id = 0
  } else {
    params.book_id = pageState.current.book_section_id[0]
    params.book_section_id = pageState.current.book_section_id[pageState.current.book_section_id.length - 1]
  }
  return params
})
const formRef = ref()
const handleChange = (res) => {
  formRef.value.pageState.isEdit = true
}

const handleSaveLessonPlan = (data) => {
  pageState.current.plan_content = data
  pageState.loading = true
}

const handleSave = async (relate_courseware_ids) => {
  currentInstance.refs.sendText.handleSaveText()
  pageState.current.relate_courseware_ids = relate_courseware_ids
  pageState.current.type = 'plan'
  const notEmptyCheckArr = {
    book_id: '教材不能为空',
    name: '名称不能为空',
    plan_content: '教案内容不能为空'
  }
  let finish = false
  for (const k in notEmptyCheckArr) {
    if (!queryParams.value[k] || queryParams.value[k] === '<p><br></p>') {
      message.error(notEmptyCheckArr[k])
      finish = true
      break
    }
  }
  if (finish) return

  try {
    let coursewareId
    if (pageState.id) {
      await editCourseware(pageState.id, queryParams.value)
      coursewareId = pageState.id
    } else {
      const { id } = await createCourseware(queryParams.value)
      coursewareId = id
    }
    await setCoursewareTags(coursewareId, { tags: pageState.current.tags })
    message.success('保存成功')
    history.go(-1)
  } catch (e) {
    console.error(e)
    message.error('保存失败')
    throw e
  }
  localStorage.removeItem('relate_ppts')
}

const getInfo = async () => {
  if (pageState.templateId) {
    const { content } = await getTeachPlanTemplateInfo(pageState.templateId)
    pageState.current.plan_content = content
  }
  if (!pageState.id) {
    return
  }
  const getInfoService = pageState.action === 'version-detail' ? getCoursewareVersionInfo : getCoursewareInfo
  pageState.current = await getInfoService(pageState.id)
  const sectionsId = pageState.current.book_section_id ?? 0
  const bookId = pageState.current.book_id ?? 0
  pageState.current.tags = pageState.current.tags.map(i => i.tag)
  pageState.current.bookShowName = pageState.current.book?.name ?? ''
  pageState.current.book_section_id = [bookId]
  if (pageState.current.book?.name && pageState.current.section?.name) {
    pageState.current.bookShowName += '-' + pageState.current.section.name
    pageState.current.book_section_id = [bookId, sectionsId]
  }
}

onBeforeMount(async () => {
  await getInfo()
})

</script>

<style></style>
